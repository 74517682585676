import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  emptyView: {
    height: 45,
    minHeight: 45,
    maxHeight: 45,
    width: '100%',
  },
  emptyViewTop: {
    top: 0,
    borderBottomColor: 'var(--game-line)',
    borderBottomWidth: 1,
    width: '100%',
    height: 45,
    position: 'absolute',
    pointerEvents: 'none',
  },
  emptyViewBtm: {
    position: 'absolute',
    bottom: 0,
    borderTopColor: 'var(--game-line)',
    borderTopWidth: 1,
    width: '100%',
    height: 45,
    pointerEvents: 'none',
  },
});
