import React, {ReactElement} from 'react';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {View, StyleSheet} from 'react-native';
import {Overlay} from '@rneui/themed';
import theme from '@/style';

interface ICustomModal {
  popVisible: boolean;
  setPopVisible: (visible: boolean) => void;
  gap?: number;
  backgroundColor?: string;
  width?: number;
  paddingHorizontal?: number;
  paddingVertical?: number;
  borderRadius?: number;
  comps?: ReactElement[];
}
const CustomModal = ({
  popVisible,
  setPopVisible,
  gap = 24,
  backgroundColor = theme.basicColor.white,
  width = 327,
  paddingHorizontal = 16,
  paddingVertical = 24,
  borderRadius = 14,
  comps,
}: ICustomModal) => {
  const {calcActualSize} = useScreenSize();
  const styles = StyleSheet.create({
    container: {
      backgroundColor,
      width: calcActualSize(width),
      paddingHorizontal: calcActualSize(paddingHorizontal),
      paddingVertical: calcActualSize(paddingVertical),
      borderRadius: calcActualSize(borderRadius),
    },
    gap24: {
      gap: calcActualSize(gap),
    },
  });

  return (
    <Overlay
      backdropStyle={{backgroundColor: 'rgba(0,0,0,0.5)'}}
      onBackdropPress={() => {
        setPopVisible(false);
      }}
      isVisible={popVisible}
      overlayStyle={[
        theme.flex.flex,
        theme.flex.col,
        styles.container,
        styles.gap24,
      ]}>
      {!!comps &&
        comps.map((item, index) => <View key={index}>{!!item && item}</View>)}
    </Overlay>
  );
};

export default CustomModal;
