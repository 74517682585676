import React from 'react';
import ErrorPage, {ErrorPageProps} from './error-page';
import Text from '../text';
import {useTranslation} from 'react-i18next';

const NoData = ({label, ...otherProps}: Omit<ErrorPageProps, 'img'>) => {
  const {i18n} = useTranslation();
  return (
    <ErrorPage
      {...otherProps}
      img={require('@/assets/svgs/no-data.svg').default}>
      <Text accent size="medium">
        {label || i18n.t('error-pages.no-data.label.title')}
      </Text>
    </ErrorPage>
  );
};

export default NoData;
